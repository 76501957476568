.box {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  
  .box .group-wrapper {
    border: 0px none;
    height: 762.62px;
    overflow-x: hidden;
    width: 224.41px;
  }
  
  .box .group {
    height: 763px;
    left: 0;
    position: fixed;
    top: 0;
    width: 226px;
  }
  
  .box .overlap-group {
    border-radius: 5px;
    height: 763px;
    position: relative;
    width: 224px;
  }
  
  .box .text-wrapper {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 21px;
    font-weight: 700;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 14px;
    white-space: nowrap;
  }
  
  .box .union {
    height: 4px;
    left: 193px;
    position: absolute;
    top: 24px;
    width: 15px;
  }
  
  .box .rectangle {
    border: 1px solid;
    border-color: var(--collection-1-dark-main);
    border-radius: 5px;
    height: 763px;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: 0;
    width: 224px;
  }
  
  .box .div {
    background-color: var(--collection-1-main-color);
    border-radius: 5px;
    height: 671px;
    left: 16px;
    opacity: 0.05;
    position: absolute;
    top: 55px;
    width: 193px;
  }
  
  .box .frame {
    background-color: var(--collection-1-white);
    border: 1px solid;
    border-color: var(--collection-1-opaque-dark-main-25);
    border-radius: 5px;
    height: 198px;
    left: 16px;
    overflow: hidden;
    position: absolute;
    top: 55px;
    width: 193px;
  }
  
  .box .text-wrapper-2 {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 21px;
    font-weight: 700;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 15px;
    white-space: nowrap;
  }
  
  .box .text-wrapper-3 {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 48px;
    white-space: nowrap;
  }
  
  .box .text-wrapper-4 {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 92px;
    white-space: nowrap;
  }
  
  .box .text-wrapper-5 {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 136px;
    white-space: nowrap;
  }
  
  .box .text-wrapper-6 {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 112px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 92px;
    white-space: nowrap;
  }
  
  .box .text-wrapper-7 {
    color: var(--collection-1-dark-main);
    font-family: "Inter-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 112px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 136px;
    white-space: nowrap;
  }
  
  .box .text-wrapper-8 {
    color: var(--collection-1-main-color);
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 76px;
  }
  
  .box .text-wrapper-9 {
    color: var(--collection-1-main-color);
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 16px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 120px;
  }
  
  .box .text-wrapper-10 {
    color: var(--collection-1-main-color);
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 112px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 76px;
  }
  
  .box .text-wrapper-11 {
    color: var(--collection-1-main-color);
    font-family: "Inter-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    left: 112px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 120px;
  }
  
  .box .ellipse {
    background-color: var(--collection-1-success);
    border-radius: 5.42px;
    height: 11px;
    left: 166px;
    position: absolute;
    top: 23px;
    width: 11px;
  }
  
  .box .vector {
    height: 10px;
    left: 164px;
    position: absolute;
    top: 173px;
    width: 16px;
  }
  
  .box .img {
    height: 19px;
    left: 16px;
    position: absolute;
    top: 168px;
    width: 23px;
  }
  
  .box .frame-2 {
    background-color: var(--collection-1-white);
    border: 1px solid;
    border-color: var(--collection-1-opaque-dark-main-25);
    border-radius: 5px;
    height: 198px;
    left: 16px;
    overflow: hidden;
    position: absolute;
    top: 262px;
    width: 193px;
  }
  
  .box .ellipse-2 {
    background-color: var(--collection-1-danger);
    border-radius: 5.42px;
    height: 11px;
    left: 166px;
    position: absolute;
    top: 23px;
    width: 11px;
  }
  
  .box .vector-2 {
    height: 19px;
    left: 16px;
    position: absolute;
    top: 169px;
    width: 23px;
  }
  
  .box .frame-3 {
    background-color: var(--collection-1-white);
    border: 1px solid;
    border-color: var(--collection-1-opaque-dark-main-25);
    border-radius: 5px;
    height: 198px;
    left: 16px;
    overflow: hidden;
    position: absolute;
    top: 468px;
    width: 193px;
  }
  
  .box .ellipse-3 {
    background-color: var(--collection-1-white);
    border-radius: 5.42px;
    height: 11px;
    left: 166px;
    position: absolute;
    top: 23px;
    width: 11px;
  }
  
  .box .vector-3 {
    height: 10px;
    left: 164px;
    position: absolute;
    top: 172px;
    width: 16px;
  }
  
  .box .frame-4 {
    height: 30px;
    left: 16px;
    position: absolute;
    top: 675px;
    width: 192px;
  }
  